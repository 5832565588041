/* Import Font Awesome for social icons */
@import '~@fortawesome/fontawesome-free/css/all.min.css';

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Import Custom Fonts */
@font-face {
  font-family: 'Mosvita-Regular';
  src: url('../public/fonts/mosvita-regular-webfont.woff2') format('woff2'),
       url('../public/fonts/mosvita-regular-webfont.woff') format('woff'),
       url('../public/fonts/Mosvita-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Hero Section Gradient Animation */
@keyframes gradientAnimation {
  0% { transform: translate(0px, 0px); }
  50% { transform: translate(20px, 20px); }
  100% { transform: translate(0px, 0px); }
}

.gradient-animation {
  animation: gradientAnimation 5s ease-in-out infinite;
}

/* Tailwind CSS Base, Components, Utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Smooth Scroll Behavior */
html {
  scroll-behavior: smooth;
}

/* General Styling */
body {
  font-family: 'Mosvita-Regular', sans-serif;
  background-color: #000000;
  color: #ffffff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Mosvita-Regular', sans-serif;
}

/* Button Styling with Hover Effect */
.btn {
  transition: transform 0.3s ease;
}

.btn:hover {
  transform: scale(1.05);
}

/* Adding Background Animation for Sections */
.section-bg-animation {
  background: linear-gradient(45deg, #1c1c1e, #282c34, #434343);
  background-size: 300% 300%;
  animation: backgroundAnimation 10s ease infinite;
}

@keyframes backgroundAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Footer Links Styling */
footer a {
  color: #ffffff;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #888; /* Change to grey when hovering over links/icons */
}

